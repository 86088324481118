.clickable{
    cursor: pointer;
}

.Home-Div {
   
}

.headerImage {
    // // background: url(../../images/code1.png) right no-repeat;
    // background: url("../../assets/images/earth-3866609.jpg");
    background: url("../../assets/images/laptop-2298286_1920georgeabdulaziz.png") no-repeat right;
    background-size: contain;
    // background-color: rgb(215, 223, 223);
    // background-color: white;
    width: 100%;
    height: auto;
    // border-bottom: solid grey 1px;

}

@media only screen and (max-width: 1000px) {
    .headerImage {
        background: none;
    }
}

.headerText {
    margin-left: 5%;
    margin-top: 1%;
    margin-bottom: 3%;
    //font-size: 2.7vw;
}


.home-about-section {
    position: relative;
    padding-bottom: 70px !important;
    padding-top: 70px !important;
}

.home-about-description {
    color: rgb(0, 0, 0) !important;
    padding-top: 100px !important;
    padding-bottom: 20px !important;
    text-align: center;
}

.home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.home-about-social {
    text-align: center !important;
    padding-top: 25px;
    color: white !important;
}

.home-about-social-links {
    justify-content: center !important;
    padding-top: 15px !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

.home-social-icons {
    position: relative !important;
    display: inline-block !important;
    width: 40px !important;
    height: 40px !important;
    text-align: center !important;
    font-size: 1.2em !important;
    line-height: 2em !important;
    background: rgba(255, 255, 255, 0.972) !important;
    border-radius: 50% !important;
    transition: 0.5s !important;
}

.home-social-icons::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #68187a;
    transition: 0.5s;
    transform: scale(0.9);
    z-index: -1;
}

.home-social-icons:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
    color: #87209e;
    box-shadow: 0 0 5px #87209e;
    text-shadow: 0 0 2px #87209e;
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
}

.icon-colour {
    color: #700c86 !important;
}

.purple {
    color: var(--link-color);
}