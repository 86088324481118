.clickable{
    cursor: pointer;
}

.project-view{
    margin: 5%;
    width: 90%;
    // margin: 30px;
    padding: 10px;
    // background-color: rgba(250, 235, 215, 0.96);
}