
.projectBlock{
    height: auto;
    max-width: 600px;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.projectBlock>a{
    background-color: var(--button-background-color);
    text-decoration: none;
}

.projectBlock>a:hover{
    background-color: var(--button-hover-color);
}

.clickable{
    cursor: pointer;
    width: fit-content;
    color: var(--link-color);

}
.clickable:hover{
    background-color: var(--button-hover-color);
}
