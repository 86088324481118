.projects{
    float: left;
    // margin: 5%;
    padding: 30px;
    // background: url("../../assets/images/splash-1763305_1280.png") repeat center;
    // background-size: cover;
    width: 90%;
    color: adjust-hue($color: black, $degrees: 10);
    // background-color: rgba(250, 235, 215, 0.96);
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 50px;
    margin-top: 10px;
    // background-color: black;
    min-height: 100vh;
}

.projects-display {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
}

.clickable{
    cursor: pointer;
}
